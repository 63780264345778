import { type cookie as CookieUtil } from '@otto-ec/global-resources/cookie'

export class Cookies {
  private static readonly IAB_CONSENT_COOKIE_NAME_LEGACY = 'iabConsent'
  static readonly IAB_CONSENT_COOKIE = 'eupubconsent-v2'

  static readonly GENERIC_CONSENT_COOKIE_NAME_ONETRUST = 'OptanonConsent'
  static readonly GENERIC_CONSENT_COOKIE_NAME_ONETRUST_APP =
    'OptanonConsent-app'

  static readonly APP_COOKIE_NAME = 'app'

  /**
 *
 */
  private static readonly IAB_CONSENT_COOKIE_NAME_ONETRUST_APP =
    'eupubconsent-v2-app'

  /**
 *
 */
  private static readonly CB_COOKIE_NAME = 'cb'

  static readonly DAYS_PER_YEAR = 365

  constructor(private readonly cookieUtil: typeof CookieUtil) {}

  public getIabConsentCookieValue(): string | undefined {
    const cookieValue = this.cookieUtil.get(Cookies.IAB_CONSENT_COOKIE)
    if (cookieValue && cookieValue !== 'null' && cookieValue !== 'undefined') {
      return cookieValue
    }
    return undefined
  }

  public setIabConsentCookieValue(value: string) {
    return this.setCookie(Cookies.IAB_CONSENT_COOKIE, value)
  }

  public getGenericConsentCookie() {
    return this.getAppCookieValue()
      ? this.getGenericConsentCookieOneTrustApp()
      : this.getGenericConsentCookieOneTrust()
  }

  setGenericConsentCookie(formattedConsents: string) {
    this.setCookieUnencoded(
      this.getAppCookieValue()
        ? Cookies.GENERIC_CONSENT_COOKIE_NAME_ONETRUST_APP
        : Cookies.GENERIC_CONSENT_COOKIE_NAME_ONETRUST,
      formattedConsents
    )
  }

  private getGenericConsentCookieOneTrust(): string | undefined {
    return this.cookieUtil.get(Cookies.GENERIC_CONSENT_COOKIE_NAME_ONETRUST)
  }

  private getGenericConsentCookieOneTrustApp(): string | undefined {
    return this.cookieUtil.get(Cookies.GENERIC_CONSENT_COOKIE_NAME_ONETRUST_APP)
  }

  public deleteLegacyCookies(): void {
    this.deleteCookie(Cookies.IAB_CONSENT_COOKIE_NAME_LEGACY)
    this.deleteCookie(Cookies.CB_COOKIE_NAME)
    this.deleteCookie(Cookies.IAB_CONSENT_COOKIE_NAME_ONETRUST_APP)
  }

  private deleteCookie(name: string): void {
    this.cookieUtil.remove(name)
  }

  private getAppCookieValue(): string | undefined {
    return this.cookieUtil.get(Cookies.APP_COOKIE_NAME)
  }

  private setCookie(
    name: string,
    value: string,
    days: number = Cookies.DAYS_PER_YEAR
  ): void {
    let safeValue = value
    if (!value) {
      safeValue = ''
    }
    this.cookieUtil.set(name, safeValue, { days })
  }

  /*                                                                                                                                                                                                         */
  private setCookieUnencoded(
    name: string,
    value: string,
    days: number = Cookies.DAYS_PER_YEAR
  ): void {
    let safeValue = value
    if (!value) {
      safeValue = ''
    }

    let timeInMinutes = 60
    const date = new Date()

    timeInMinutes += days * 24 * 60

    date.setTime(date.getTime() + timeInMinutes * 60 * 1000)

    safeValue += `; expires=${date.toUTCString()}`
    safeValue += `; path="/"`

    document.cookie = name + '=' + safeValue
  }
}
