import { LoggingService } from './logging-service'
import { logger } from '@otto-ec/global-resources/debug'

const log = new LoggingService()
const debugLog = logger('eprivacy.privacy.cmp')

export function logDeprecatedUsage(
  methodName: string,
  extraMessage?: string
): void {
  /*                                     */
  const logMessage = new Error(
    `Deprecated method called: '${methodName}'. ${extraMessage ?? ''}`.trim()
  )
  debugLog.warn(logMessage)
  log.logError(logMessage)
}
