import { Vendor } from './iab-tcf/vendor.enum'
import { ReceiptArchiver } from './receipt-archiver'
import { LoggingService } from '../utils/logging-service'
import { consentChanged } from '@otto-ec/eprivacy-cmp'
import { Cmp } from './cmp.type'
import { logDeprecatedUsage } from '../utils/log-deprecated-usage'
import { memoizeLastSuccess } from '../utils/memoize-last-success'
import { Cookies } from '../adapter/cookies'
import { ConsentProviderAdapter } from '../adapter/consent-provider.adapter'
import { CmpIabTcfApiService } from './iab-tcf/cmp-iab-tcf-api.service'

export class CmpImpl implements Cmp {
  readonly #receiptArchiver: ReceiptArchiver

  readonly #loggingService: LoggingService
  readonly #cookies: Cookies
  readonly #consentProvider: ConsentProviderAdapter
  readonly #tcModelService: CmpIabTcfApiService

  constructor(
    consentProvider: ConsentProviderAdapter,
    tcModelService: CmpIabTcfApiService,
    receiptArchiver: ReceiptArchiver,
    cookies: Cookies,
    loggingService: LoggingService
  ) {
    this.#consentProvider = consentProvider
    this.#tcModelService = tcModelService
    this.#cookies = cookies
    this.#receiptArchiver = receiptArchiver
    this.#loggingService = loggingService
  }

  public togglePreferenceCenter(): void {
    this.#consentProvider.togglePreferenceCenter()
  }

  public rejectAll(): void {
    this.#consentProvider.rejectAll()
  }

  /*                 */
  public allowAll(): void {
    this.#consentProvider.allowAll()
  }

  /**
 *
 *
 *
 */
  public rejectGeneralVendorConsent(generalVendorId: string): void {
    this.#consentProvider.rejectGeneralVendorConsent(generalVendorId)
  }

  /**
 *
 */
  public getIabConsentString(): undefined | string {
    return this.#cookies.getIabConsentCookieValue()
  }

  public emitConsentChangeEvent(): void {
    consentChanged()

    /*                                                                    */
    /*                          */
    window.o_global.eventQBus.emit('eprivacy.cmp.consentChanged')
  }

  public async processAppConsentV2(
    consentId: string,
    userConsents: {
      [key: string]: number | string
    } | null = {},
    iabString: string
  ): Promise<boolean> {
    if (!consentId) {
      this.#loggingService.logError(
        new Error('consentId is required to process app consent')
      )
      return false
    }

    const normalizedUserConsents = this.#normalizeUserConsents(userConsents)

    this.#setAppConsentCookie(normalizedUserConsents, consentId)
    this.#setAppIabCookie(iabString)

    this.emitConsentChangeEvent()
    try {
      await this.#memoizedAppsArchiveReceipt(
        consentId,
        normalizedUserConsents,
        iabString
      )
    } catch (error) {
      this.#loggingService.logError(error)
      return false
    }
    return true
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  readonly #memoizedAppsArchiveReceipt = memoizeLastSuccess(
    'eprivacy.cmp.app-last-archive-receipt-call',
    async (
      consentId,
      /*                                                      */
      /*                                                         */
      userConsents: Record<string, number> | null,
      /*                                                      */
      /*                                                         */
      iabString: string
    ) => {
      await this.archiveReceipt(consentId, 'OneTrust-CB-App')
      return true
    }
  )

  /**
 *
 *
 *
 */
  public readVendorsConsents(): Promise<Map<number, boolean>> {
    return this.#tcModelService.readVendorsConsent(
      this.#cookies.getIabConsentCookieValue()
    )
  }

  public async getAppGoogleTrackingConsent(): Promise<boolean> {
    const tcfVendorsConsents = await this.readVendorsConsents()
    const tcfConsent755 = !!tcfVendorsConsents.get(
      Vendor.GOOGLE_ADVERTISING_PRODUCTS
    )
    return (
      this.getConsentForGV('GOANA') &&
      this.getConsentForGV('GOREM') &&
      tcfConsent755
    )
  }

  /*                                                                */
  public async getAdjustTrackingConsent(): Promise<boolean> {
    const tcfVendorsConsents = await this.readVendorsConsents()
    const tcfConsent16 = !!tcfVendorsConsents.get(Vendor.RTB_HOUSE)
    const tcfConsent755 = !!tcfVendorsConsents.get(
      Vendor.GOOGLE_ADVERTISING_PRODUCTS
    )

    return (
      this.getOttoOwnPurposesConsent() &&
      this.getConsentForGV('METAR') &&
      this.getConsentForGV('PINRT') &&
      this.getConsentForGV('TKADV') &&
      this.getConsentForGV('SNAPA') &&
      this.getConsentForGV('GOREM') &&
      tcfConsent16 &&
      tcfConsent755
    )
  }

  /**
 *
 */
  public async acceptAllConsents(): Promise<void> {
    logDeprecatedUsage('acceptAllConsents')
    await this.#tcModelService.acceptAllConsents()
  }

  /**
 *
 */
  public async rejectAllConsents(): Promise<void> {
    logDeprecatedUsage('rejectAllConsents')
    await this.#tcModelService.rejectAllConsents()
  }

  public async archiveReceipt(
    consentId: string | null,
    source: string | null
  ): Promise<boolean> {
    if (consentId === null || source === null) {
      return Promise.resolve(false)
    }
    try {
      return await this.#receiptArchiver.archiveReceipt(consentId, source)
    } catch (e) {
      this.#loggingService.logError(e)
      throw e
    }
  }

  public getConsentForGV(key: string): boolean {
    const { genVendors, groups } = this.#loadAndParseOptanonConsentIntern()
    return genVendors[key] === '1' || groups[key] === '1'
  }

  public getConsentsForAllGVs(): { [key: string]: string } {
    const parsedCookie = this.#loadAndParseOptanonConsentIntern()
    return { ...parsedCookie.genVendors, ...parsedCookie.groups }
  }

  public getOttoOwnPurposesConsent(): boolean {
    return this.getConsentForGV('OTTOT') || this.getConsentForGV('OTTON')
  }

  #normalizeUserConsents(
    userConsents: { [key: string]: number | string } | null
  ): {
    [p: string]: number
  } | null {
    if (userConsents == null || Object.keys(userConsents).length === 0) {
      return null
    }
    const result: Record<string, number> = {}

    for (const key in userConsents) {
      const numValue = Number(userConsents[key])
      if (!isNaN(numValue)) {
        result[key] = numValue
      } else {
        const error = new Error(
          `Value for ${key} is not a valid number or numeric string.`
        )
        this.#loggingService.logError(error)
        throw error
      }
    }

    return { ...userConsents, ...result } as Record<string, number>
  }

  #setAppConsentCookie(
    userConsents: { [p: string]: number } | null,
    consentId: string
  ): void {
    if (userConsents == null) {
      return
    }

    const genVendorsKeyValueString = Object.entries(userConsents).map((entry) =>
      entry.join(':')
    )
    const formattedConsents =
      'genVendors=' + encodeURIComponent(genVendorsKeyValueString.join(','))

    const cookieValue = `${formattedConsents}&consentId=${consentId}`

    this.#cookies.setGenericConsentCookie(cookieValue)
  }

  #setAppIabCookie(iabString: string) {
    this.#cookies.setIabConsentCookieValue(iabString)
  }

  #loadAndParseOptanonConsentIntern(): {
    genVendors: Record<string, string>
    groups: Record<string, string>
  } {
    const optanonConsentCookie = this.#cookies.getGenericConsentCookie()

    if (!optanonConsentCookie) {
      return { genVendors: {}, groups: {} }
    }

    const decodedCookieValue = decodeURIComponent(optanonConsentCookie)

    const matchGenVendors = decodedCookieValue.match(/genVendors=([^&]*)/)
    const matchGroups = decodedCookieValue.match(/groups=([^&]*)/)

    const genVendors = this.#extractVendors(matchGenVendors?.[1])
    const groups = this.#extractVendors(matchGroups?.[1])

    return { genVendors, groups }
  }

  #extractVendors(genVendorCookieString?: string): { [p: string]: string } {
    if (!genVendorCookieString) {
      return {}
    }
    const keyValuePairsGenVendors = genVendorCookieString
      .split(',')
      .filter((e) => e !== '')
      .map((pair) => pair.split(':'))
    return keyValuePairsGenVendors.reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    )
  }
}
