import { Vendor, vendors } from './vendor.enum'
import {
  GVL,
  PurposeRestriction,
  RestrictionType,
  TCModel,
  TCString,
} from '@iabtechlabtcf/core'
import { LoggingService } from '../../utils/logging-service'
import { CmpApi } from '@iabtechlabtcf/cmpapi'
import { allowedPurposes } from './purpose.enum'

const OTTO_CMP_ID = 284
const CMP_VERSION = 2
const CONSENT_LANGUAGE = 'DE'
const PUBLISHER_COUNTRY_CODE = 'DE'
const IS_SERVICE_SPECIFIC = true

const CONSENT_SCREEN = 1

export class CmpIabTcfApiService {
  private cmpApi: CmpApi
  private tcModel?: TCModel

  constructor(
    gvlPath: string,
    private readonly loggingService: LoggingService
  ) {
    this.cmpApi = new CmpApi(OTTO_CMP_ID, CMP_VERSION, IS_SERVICE_SPECIFIC) /*                      */
    GVL.baseUrl = gvlPath
  }

  update(iabConsentString: string) {
    this.cmpApi.update(iabConsentString)
  }

  public async readVendorsConsent(
    iabConsentEncoded?: string
  ): Promise<Map<Vendor, boolean>> {
    const vendorsConsent = this.initDefaultVendorsConsentMap()
    if (!iabConsentEncoded) {
      return vendorsConsent
    }
    try {
      let tcModel = this.getTcModel()
      await tcModel.gvl.readyPromise

      tcModel = TCString.decode(iabConsentEncoded, tcModel)

      for (const vendorId of vendorsConsent.keys()) {
        const consentGiven = tcModel.vendorConsents.has(vendorId)
        vendorsConsent.set(vendorId, consentGiven)
      }
      return vendorsConsent
    } catch (reason) {
      this.loggingService.logError(
        reason,
        'Error while reading vendors consent',
        iabConsentEncoded
      )
      return this.initDefaultVendorsConsentMap()
    }
  }

  private getTcModel(): TCModel {
    if (!this.tcModel) {
      const tcModel = new TCModel()
      tcModel.cmpId = OTTO_CMP_ID
      tcModel.publisherCountryCode = PUBLISHER_COUNTRY_CODE
      tcModel.cmpVersion = CMP_VERSION
      tcModel.isServiceSpecific = IS_SERVICE_SPECIFIC
      tcModel.consentLanguage = CONSENT_LANGUAGE
      tcModel.consentScreen = CONSENT_SCREEN

      tcModel.gvl = new GVL()

      const date = new Date()
      date.setHours(0, 0, 0, 0)
      tcModel.created = date
      tcModel.lastUpdated = date
      this.tcModel = tcModel
    }
    return this.tcModel
  }

  private initDefaultVendorsConsentMap() {
    const vendorsConsentStates: Map<Vendor, boolean> = new Map()
    vendors.forEach((vendorId) => {
      vendorsConsentStates.set(vendorId, false)
    })
    return vendorsConsentStates
  }

  /**
 *
 */
  public async rejectAllConsents(): Promise<string> {
    try {
      const tcModel = this.getTcModel()
      await tcModel.gvl.readyPromise

      tcModel.unsetAll()
      tcModel.unsetAllVendorsDisclosed()

      const iabConsentString = TCString.encode(tcModel)
      this.update(iabConsentString)
      return iabConsentString
    } catch (reason: unknown) {
      this.loggingService.logError(reason)
      throw reason
    }
  }

  /**
 *
 */
  public async acceptAllConsents(): Promise<string> {
    try {
      const tcModel = this.getTcModel()
      await tcModel.gvl.readyPromise
      tcModel.gvl.narrowVendorsTo(vendors)
      tcModel.setAllVendorConsents()
      /*                           */
      allowedPurposes.forEach((purposeId) => {
        tcModel.purposeConsents.set(purposeId)
        tcModel.publisherConsents.set(purposeId)

        const purposeRestriction =
          this.createRequireConsentPublisherRestriction(purposeId)
        vendors.forEach((vendorId) => {
          tcModel.publisherRestrictions.add(vendorId, purposeRestriction)
        })
      })

      const iabConsentString = TCString.encode(tcModel)
      this.update(iabConsentString)
      return iabConsentString
    } catch (reason: unknown) {
      this.loggingService.logError(reason)
      throw reason
    }
  }

  private createRequireConsentPublisherRestriction(purposeId: number) {
    return new PurposeRestriction(purposeId, RestrictionType.REQUIRE_CONSENT)
  }
}
