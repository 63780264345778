import { NEXUS_CMP_NAMESPACE } from '@otto-ec/eprivacy-cmp'
import { defineEvent } from '@otto-ec/global-resources/nexus'
import { Cmp } from '../cmp.type'

export function initNexus(cmp: Cmp): void {
  otto[NEXUS_CMP_NAMESPACE].set({
    togglePreferenceCenter: cmp.togglePreferenceCenter.bind(cmp),
    rejectGeneralVendorConsent: cmp.rejectGeneralVendorConsent.bind(cmp),
    rejectAll: cmp.rejectAll.bind(cmp),
    allowAll: cmp.allowAll.bind(cmp),
    getConsentForGV: cmp.getConsentForGV.bind(cmp),
    readVendorsConsents: cmp.readVendorsConsents.bind(cmp),
    archiveReceipt: cmp.archiveReceipt.bind(cmp),
    emitConsentChangeEvent: cmp.emitConsentChangeEvent.bind(cmp),
    getOttoOwnPurposesConsent: cmp.getOttoOwnPurposesConsent.bind(cmp),
    getAppGoogleTrackingConsent: cmp.getAppGoogleTrackingConsent.bind(cmp),
    getAdjustTrackingConsent: cmp.getAdjustTrackingConsent.bind(cmp),
    getConsentsForAllGVs: cmp.getConsentsForAllGVs.bind(cmp),
    getIabConsentString: cmp.getIabConsentString.bind(cmp),
    consentChanged: defineEvent(),
  })
}
