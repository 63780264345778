export enum Vendor {
  RTB_HOUSE = 16,
  XANDR = 32,
  ADITION = 39,
  ACTIVE_AGENT = 40,
  ADEX = 44,
  ADFORM = 50,
  FLASHTALKING = 78,
  /*                 */
  TEADS = 132,
  /*                        */
  KANTAR_GROUP = 345,
  NIELSEN_MEDIA_RESEARCH = 373,
  AUDIENCE_PROJECT = 394,
  OTTO = 559,
  GOOGLE_ADVERTISING_PRODUCTS = 755,
  /*        */
  REV_JET = 759,
  REPPUBLIKA = 800,
  MICROSOFT_ADVERTISING = 1126,
}

export const vendors = [
  Vendor.ACTIVE_AGENT,
  Vendor.ADFORM,
  Vendor.ADITION,
  Vendor.ADEX,
  Vendor.AUDIENCE_PROJECT,
  /*                  */
  Vendor.FLASHTALKING,
  /*         */
  Vendor.GOOGLE_ADVERTISING_PRODUCTS,
  /*                         */
  Vendor.KANTAR_GROUP,
  Vendor.MICROSOFT_ADVERTISING,
  Vendor.NIELSEN_MEDIA_RESEARCH,
  Vendor.OTTO,
  Vendor.REPPUBLIKA,
  Vendor.RTB_HOUSE,
  Vendor.REV_JET,
  Vendor.TEADS,
  Vendor.XANDR,
]
